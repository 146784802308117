import { closePopup, getPopupRegistration, openPopup, Priority } from 'app/PopupHolder';
import userImg from 'assets/images/account-page/user-icon-lg.png';
import classNames from "classnames";
import Modal from 'components/base/Modal';
import { ErrorMessage, Form, Formik } from "formik";
import React from 'react';
import { isValidPhone } from 'utils/validation';
import * as Yup from "yup";

import config from 'config';
import { loadUserProfileSuccess } from "data/user/actions";
import { selectUserProfile } from "data/user/selectors";
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import FormikText from '../components/Formik/FormikText';
import css from "../style.scss";

import { UsStateOptions } from 'data/user/constants';
import FormikSelect from '../components/Formik/FormikSelect';
import UploadProfilePicture from '../components/uploadProfilePicture';

const {
    constants: {
        API: { PRICING_UPDATE_URL },
    },
} = config;

const EditUserProfilePopup = (props) => {

    const dispatch = useDispatch();
    const selectUserProfileSection = useSelector(selectUserProfile).toJS();
    const [imageUrl, setImageUrl] = useState(selectUserProfileSection?.profilePicture || '');

    // Validation schema
    const validationSchema = Yup.object({
        firstName: Yup.string().required("First Name is required."),
        lastName: Yup.string().required("Last Name is required"),
        phoneNumber1: Yup.string().test('is-valid-phone', 'Phone Number 1 is invalid.', (value) =>
            value ? isValidPhone(value.trim()) : true
        ),
        phoneNumber2: Yup.string().test('is-valid-phone', 'Phone Number 2 is invalid.', (value) =>
            value ? isValidPhone(value.trim()) : true
        ),
        addressLine1: Yup.string(),
        addressLine2: Yup.string(),
        city: Yup.string(),
        state: Yup.string(),
        zipCode: Yup.string().matches(/^\d{5}(-?\d{4})?$/, "Zip Code is invalid."),
    });

    const initialValues = {
        firstName: selectUserProfileSection?.firstName || '',
        lastName: selectUserProfileSection?.lastName || '',
        phoneNumber1: selectUserProfileSection?.phoneNumber1 || '',
        phoneNumber2: selectUserProfileSection?.phoneNumber2 || '',
        addressLine1: selectUserProfileSection?.addressLine1 || '',
        addressLine2: selectUserProfileSection?.addressLine2 || '',
        city: selectUserProfileSection?.city || '',
        state: selectUserProfileSection?.state || '',
        zipCode: selectUserProfileSection?.zipCode || '',
        profilePicture: selectUserProfileSection?.profilePicture || '',
    };

    const getFullDataURL = (base64String) => {
        // Decode the Base64 string to check its format
        const binaryString = atob(base64String);

        // Get the first 4 bytes (magic number) as hexadecimal
        const first4Bytes = binaryString
            .slice(0, 4)
            .split("")
            .map((char) => char.charCodeAt(0).toString(16).padStart(2, "0"))
            .join(" ");

        // Determine the MIME type based on the magic number
        let mimeType = "";
        if (first4Bytes === "89 50 4e 47") {
            mimeType = "image/png";
        } else if (first4Bytes.startsWith("ff d8 ff")) {
            mimeType = "image/jpeg";
        } else if (binaryString.trim().startsWith("<svg")) {
            mimeType = "image/svg+xml";
        } else {
            throw new Error("Unknown image format");
        }

        // Construct the full Data URL
        return `data:${mimeType};base64,${base64String}`;
    }

    const handleClose = () => {
        props.closePopup();
    };

    const handleSubmit = (values) => {
        let payload = { ...selectUserProfileSection, ...values }
        fetch(`${PRICING_UPDATE_URL}/ps/profile`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(payload),
        })
            .then((response) => {
                if (response.status === 200) {
                    return response.json();
                } else {
                    if (response.status === 400) {
                        const errorMessage = response.text(); // Get response body for debugging
                        throw new Error(`Failed with status ${response.status}: ${errorMessage}`);
                    }
                }
            })
            .then((data) => {
                console.log('Response from API:', data);
                dispatch(loadUserProfileSuccess(data));
                handleClose()
            })
            .catch((error) => {
                console.error("Error occurred:", error.message);
                console.error('Error:', error);
            });
    };

    return (
        <Modal className={classNames(css.clsAccPageModals, css.clsBillingModal, css.clsAccEditProfileModal)} isOpen uniqId="EditUserProfilePopup" width="auto" padding="0px 0px 0px" onClose={handleClose}>
            <div className={classNames(css.modal_lg, "modal-dialog modal-lg m-0 modal-dialog-scrollable")}>
                <div className={classNames(css.clsModalContent, "modal-content")}>
                    <div className={classNames(css.clsModalContentBody, "modal-body")}>
                        <Formik
                            initialValues={initialValues}
                            validationSchema={validationSchema}
                            onSubmit={(values) => {
                                handleSubmit(values);
                            }}
                        >
                            {({ touched, errors, handleSubmit, setFieldValue, values, errorMessage }) => (
                                <Form onSubmit={handleSubmit}>
                                    <div className={css.clsModalHeader}>
                                        <h2 className={css.clsModalTitle}>Edit Profile Info</h2>
                                    </div>

                                    <div className={classNames(css.clsModalBody)}>
                                        <div className="clearfix w-100">
                                            <div className="clearfix">
                                                <h4 className={css.clsRequiredTxt}>Indicates Required Field<span className={css.text_primary}>*</span></h4>
                                                <h4 className={classNames(css.clsModalSubTitle2, css.pb_16, "text-left")}> General Information </h4>

                                                <div className={classNames(css.rowInfo, "flex-column-reverse flex-lg-row row")}>
                                                    <div className={classNames(css.col_lg_8, "col-lg-8 col-12")}>
                                                        <div className={classNames(css.clsFormGroup, css.mb_24)}>
                                                            <FormikText name="firstName" label="First Name" placeholder="First Name" errors={errors} touched={touched} required={true} />
                                                            <ErrorMessage name="firstName" component="p" className={css.clsFormErrorText} render={errorMessage} />
                                                        </div>
                                                        <div className={classNames(css.clsFormGroup, css.mb_24)}>
                                                            <FormikText name="lastName" label="Last Name" placeholder="Last Name" errors={errors} touched={touched} required={true} />
                                                            <ErrorMessage name="lastName" component="p" className={css.clsFormErrorText} render={errorMessage} />
                                                        </div>
                                                        <div className={classNames(css.rowInfoInner, "row")}>
                                                            <div className={classNames(css.col_lg_6, css.mb_lg_0, css.mb_24, "col-lg-6 col-12")}>
                                                                <div className={classNames(css.clsFormGroup)}>
                                                                    <FormikText name="phoneNumber1" label="Phone Number 1" errors={errors} placeholder="Phone Number 1" touched={touched} />
                                                                </div>
                                                            </div>
                                                            <div className={classNames(css.col_lg_6, "col-lg-6 col-12")}>
                                                                <div className={classNames(css.clsFormGroup)}>
                                                                    <FormikText name="phoneNumber2" label="Phone Number 2" errors={errors} placeholder="Phone Number 2" touched={touched} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className={classNames(css.col_lg_4, "col-lg-4 col-md-6 col-12")}>
                                                        <div className={classNames(css.clsFormGroup, "d-flex flex-column justify-content-center align-items-center")}>
                                                            <label className={classNames(css.clsFormLabel, css.mb_12)}>  Profile Picture </label>
                                                            <div className={css.clsSelectProfileImg}>
                                                                <div className={css.clstProfileImg}>
                                                                    {imageUrl ? (
                                                                        <img src={getFullDataURL(imageUrl)} alt="Profile" />
                                                                    ) : (
                                                                        <img src={userImg} alt="Profile" />
                                                                    )}
                                                                </div>
                                                                <UploadProfilePicture filed={'profilePicture'} setFieldValue={setFieldValue} setImageUrl={setImageUrl} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className={classNames(css.mt_48, "clearfix")}>
                                                <h4 className={classNames(css.clsModalSubTitle2, css.mb_24, "text-left")}> Address </h4>

                                                <div className={classNames(css.rowAddress, "row")}>
                                                    <div className={classNames(css.col_lg_6, css.mb_24, "col-lg-6 col-12")}>
                                                        <div className={css.clsFormGroup}>
                                                            <FormikText name="addressLine1" label="Address Line 1" placeholder="Address Line 1" errors={errors} touched={touched} />
                                                        </div>
                                                    </div>
                                                    <div className={classNames(css.col_lg_6, css.mb_24, "col-lg-6 col-12")}>
                                                        <div className={css.clsFormGroup}>
                                                            <FormikText name="addressLine2" label="Address Line 2" placeholder="Address Line 2" errors={errors} touched={touched} />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className={classNames(css.rowAddress, "row")}>
                                                    <div className={classNames(css.col_lg_6, css.mb_lg_0, css.mb_24, "col-lg-6 col-12")}>
                                                        <div className={css.clsFormGroup}>
                                                            <FormikText name="city" label="City" placeholder="City" errors={errors} touched={touched} />
                                                        </div>
                                                    </div>
                                                    <div className={classNames(css.col_lg_6, "col-lg-6 col-12")}>
                                                        <div className={classNames(css.rowAddressInner, "row")}>
                                                            <div className={classNames(css.col_lg_6, css.mb_lg_0, css.mb_24, "col-lg-6 col-12")}>
                                                                <div className={css.clsFormGroup}>
                                                                    <label
                                                                        className={classNames(
                                                                            css.clsFormLabel,
                                                                            errors['state'] && touched['state'] && css.text_danger
                                                                        )}
                                                                    >State</label>
                                                                    <FormikSelect
                                                                        name="state"
                                                                        label=""
                                                                        placeholder="State"
                                                                        options={UsStateOptions}
                                                                        className={css.clsFormSelect}
                                                                        errors={errors}
                                                                        touched={touched}
                                                                    />

                                                                </div>
                                                            </div>
                                                            <div className={classNames(css.col_lg_6, "col-lg-6 col-12")}>
                                                                <div className={css.clsFormGroup}>
                                                                    <FormikText name="zipCode" label="Zip Code" placeholder="Zip Code" errors={errors} touched={touched} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className={classNames(css.clsModalFooter)}>
                                        <button className={classNames(css.btn_lg, css.btn_primary, css.text_primary,)} onClick={handleClose}> Cancel</button>
                                        <button className={classNames(css.btn_lg, css.clsBtnOrng)} type="submit" >  Save </button>
                                    </div>

                                </Form>
                            )}
                        </Formik>
                    </div>

                </div>
            </div>

        </Modal >
    );
};


const registrationId = getPopupRegistration(EditUserProfilePopup);
EditUserProfilePopup.open = (props = {}) => openPopup(registrationId, { ...props, priority: Priority.MEDIUM });
EditUserProfilePopup.close = () => closePopup({ popup: registrationId });

export default EditUserProfilePopup;
