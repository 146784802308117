import {
    closePopup,
    getPopupRegistration,
    openPopup,
    Priority
} from "app/PopupHolder";
import visaCard from "assets/images/account-page/visa-card.svg";
import classNames from "classnames";
import Modal from "components/base/Modal";
import React from "react";
import CreditCardDropdown from "../components/CreditCardDropdown";
import css from "../style.scss";

const EditDefaultPaymentPopup = (props) => {
  const handleClose = () => {
    
    props.closePopup();
  };
  return (
    <Modal
      className={classNames(css.clsAccPageModals, css.clsBillingModal)}
      isOpen
      uniqId="MakeDefaultPaymentPopup"
      caption=""
      width="auto"
      padding="0px 0px 0px"
    >
      {/* Remove Default Payment Method? */}
      <div className={classNames(css.modal_lg, "modal-dialog modal-lg m-0 modal-dialog-scrollable")}>
        <div className={classNames(css.clsModalContent, "modal-content")}>
            <div className={classNames(css.clsModalContentBody, "modal-body")}>
            <div className={css.clsModalHeader}>
              <h2 className={css.clsModalTitle}>Remove Default Payment Method?</h2>
              <h5 className={css.clsModalSubTitle}>
                A New Default Payment Method Must Be Setup for Recurring Services.
                This can be changed at any time in the Account Page under Billing.
              </h5>
            </div>

            <div className={css.clsModalBody}>
              <div className="row justify-content-center w-100">
                <div className="col-lg-11">
                  <p className={css.clsBodyTxtLine1}>
                    This Card Will Be Removed From Your Account
                  </p>
                  <div
                    className={classNames(
                      css.clsPayCard,
                      css.clsPayCardFull,
                      css.align_items_center,
                      css.justify_content_between
                    )}
                  >
                    <div
                      className={classNames(css.d_flex, css.align_items_center)}
                    >
                      <img src={visaCard} className="mr-3" alt="" />
                      <div className="clearfix">
                        <h5 className={css.clsMediaTxt1}>Ending in 1509</h5>
                        <p className={css.clsMediaTxt2}>Exp. 05/2026</p>
                      </div>
                    </div>
                    <p
                      className={classNames(
                        css.clsBtnSm,
                        css.clsBtnPrimaryGreen,
                        "mb-0"
                      )}
                    >
                      Default for Recurring Services
                    </p>
                  </div>
                </div>

                <div className="col-lg-11 pt-4">
                  <p className={css.clsBodyTxtLine1}>
                    Choose a New Default Payment Method for Recurring Services
                  </p>
                  <CreditCardDropdown
                    tag={true}
                    options={[{ label: "A", cardEnding: '0059' , expDate: '05/2026' }, { label: "B", cardEnding: '0100' , expDate: '65/2027' }, { label: "C",cardEnding: '0099' , expDate: '05/2028' }]}
                  />
                  {/* <div className={css.clsDropdownCard}>
                    <div
                      className={classNames(
                        css.clsPayCard,
                        css.clsPayCardFull,
                        css.align_items_center,
                        css.justify_content_between,
                        "flex-fill"
                      )}
                    >
                      <div
                        className={classNames(
                          css.d_flex,
                          css.align_items_center
                        )}
                      >
                        <img src={visaCard} className="mr-3" alt="" />
                        <div className="clearfix">
                          <h5 className={css.clsMediaTxt1}>Ending in 1509</h5>
                          <p className={css.clsMediaTxt2}>Exp. 05/2026</p>
                        </div>
                      </div>
                      <p
                        className={classNames(
                          css.clsBtnSm,
                          css.clsBtnPrimaryGreen,
                          "mb-0"
                        )}
                      >
                        Default for Recurring Services
                      </p>
                    </div>
                    <a className={css.pl_16} href="javascript:;">
                      <img src={downArrow} alt="" />
                    </a>
                  </div> */}
                </div>
              </div>
            </div>

            <div className={classNames(css.clsModalFooter)}>
              <button
                className={classNames(
                  css.btn_lg,
                  css.btn_primary,
                  css.text_primary
                )}
                onClick={handleClose}
              >
                {" "}
                Cancel
              </button>
              <button
                className={classNames(css.btn_lg, css.clsBtnOrng)}
              >
                {" "}
                Remove Card And Confirm New Default
              </button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

const registrationId = getPopupRegistration(EditDefaultPaymentPopup);
EditDefaultPaymentPopup.open = (props = {}) =>
  openPopup(registrationId, { ...props, priority: Priority.MEDIUM });
EditDefaultPaymentPopup.close = () => closePopup({ popup: registrationId });

export default EditDefaultPaymentPopup;
