

import { closePopup, getPopupRegistration, openPopup, Priority } from 'app/PopupHolder';
import Modal from 'components/base/Modal';
import React from 'react';
import classNames from "classnames";
import css from "../style.scss";
import visaCard from 'assets/images/account-page/visa-card.svg'
import downArrow from 'assets/images/account-page/down-arrow.svg'
import { CircularProgress } from '../Sections/UsageSections';
import CreditCardDropdown from '../components/CreditCardDropdown';


const PurchaseSavesAndExportsPopup = (props) => {

  const handleClose = () => {
    props.closePopup();
  };
  return (
    <Modal className={classNames(css.clsAccPageModals, css.clsBillingModal)} isOpen uniqId="PurchaseSavesAndExportsPopup" width="auto" padding="0px 0px 0px" onClose={handleClose}>
      
       {/* Purchase Saves & Exports */}
       <div className={classNames(css.modal_lg, "modal-dialog modal-lg m-0 modal-dialog-scrollable")}>
        <div className={classNames(css.clsModalContent, "modal-content")}>
            <div className={classNames(css.clsModalContentBody, "modal-body")}>
            <div className={css.clsModalHeader}>
              <h2 className={css.clsModalTitle}>Purchase Saves & Exports</h2>
            </div>

            <div className={classNames(css.clsModalBody)}>
              <div className="clearfix w-100">
                <h4 className={classNames(css.clsModalSubTitle2, css.mb_12, "text-left")}> Current Usage </h4>
                <div className={css.clsUsageCard}>
                  <ul className={classNames(css.clsUsageList, css.unstyled)}>
                    <li
                      className={classNames(
                        css.clsBorderRight
                      )}
                    >
                      <div className={css.clsUsageContent}>
                        <CircularProgress
                          percentage={100 - 76}
                          type="saveremaing" 
                        />
                        <div className={css.clsUsageContentTxt}>
                          <div className={css.clsInfoPopup}>
                            <h5 className={css.clsUsageContent1}>
                              <span>Saves Remaining </span>
                            </h5>
                          </div>

                          <h4 className={css.clsUsageContent2}>12,000</h4>
                          <div className={css.clsUsageContent3}>
                            <h6>38,000</h6>
                            <span className={css.clsBorder}></span>
                            <h6>76%</h6>
                            <h6>Used</h6>
                          </div>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className={css.clsUsageContent}>
                        <CircularProgress
                          percentage={100 - 76}
                          type="exportremaining" 
                        />
                        <div className={css.clsUsageContentTxt}>
                          <div className={css.clsInfoPopup}>
                            <h5 className={css.clsUsageContent1}>
                              <span>Exports Remaining</span>
                            </h5>
                          </div>
                          <h4 className={css.clsUsageContent2}>8,000</h4>
                          <div className={css.clsUsageContent3}>
                            <h6>42,000</h6>
                            <span className={css.clsBorder}></span>
                            <h6>76%</h6> 
                            <h6>Used</h6>
                          </div>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
                <hr className='my-5'></hr>
                <div className={classNames(css.pb_30, "row")}>
                  <div className="col-lg-7 col-12">
                    <div className={classNames(css.pb_10, "d-flex align-items-end")}>
                      <span className={classNames(css.clsModalSubTitle2, "text-left mr-3")}>Saves </span>
                      <span className={classNames(css.text_16, css.text_sb, css.text_gray, css.pr_10, css.clsBorderRight, "mb-1")}>3,000 <span className={classNames(css.mr_3, css.text_gray, css.text_14, css.text_m)}>min. qty.</span> </span>
                      <span className={classNames(css.text_16, css.text_sb, css.text_gray, css.pl_10, css.clsBorderLeft, "mb-1")}>$0.01 <span className={classNames(css.mr_3, css.text_gray, css.text_14, css.text_m)}>each</span> </span>
                    </div>
                    <p className={classNames(css.text_14, css.text_m, css.text_gray, "mb-0")}>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.</p>
                  </div>
                  <div className="col-lg-5 col-12">
                    <div className="row justify-content-end">
                      <div className="col-lg-8 col-12">
                        <div className={classNames(css.clsFormGroup)}>
                          <label className={classNames(css.clsFormLabel)}>Saves Quantity </label>
                          <select className={classNames(css.clsFormControl, css.clsFormSelect)}
                          style={{ backgroundImage: `url(${downArrow})` }}
                          >
                            <option selected disabled >MM</option>
                            <option value="1">One</option>
                            <option value="2">Two</option>
                            <option value="3">Three</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={classNames("row justify-content-between")}>
                  <div className="col-lg-7 col-12">
                    <div className={classNames(css.pb_10, "d-flex align-items-end")}>
                      <span className={classNames(css.clsModalSubTitle2, "text-left mr-3")}>Exports </span>
                      <span className={classNames(css.text_16, css.text_sb, css.text_gray, css.pr_10, css.clsBorderRight, "mb-1")}>5<span className={classNames(css.mr_3, css.text_gray, css.text_14, css.text_m)}>min. qty.</span> </span>
                      <span className={classNames(css.text_16, css.text_sb, css.text_gray, css.pl_10, css.clsBorderLeft, "mb-1")}> $0.02 <span className={classNames(css.mr_3, css.text_gray, css.text_14, css.text_m)}>each</span> </span>
                    </div>
                    <p className={classNames(css.text_14, css.text_m, css.text_gray, "mb-0")}>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.</p>
                  </div>
                  <div className="col-lg-5 col-12">
                    <div className="row justify-content-end">
                      <div className="col-lg-8 col-12">
                        <div className={classNames(css.clsFormGroup)}>
                          <label className={classNames(css.clsFormLabel)}>Exports Quantity </label>
                          <select className={classNames(css.clsFormControl, css.clsFormSelect)}
                          style={{ backgroundImage: `url(${downArrow})` }}
                          >
                            <option selected disabled >MM</option>
                            <option value="1">One</option>
                            <option value="2">Two</option>
                            <option value="3">Three</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <hr className='my-5'></hr>

                <div className={classNames("row align-items-center")}>
                  <div className={classNames(css.clsBorderRight, "col-lg-7")}>
                    <div className={classNames(css.clsFormGroup)}>
                      <label className={classNames(css.clsFormLabel)}> Payment Method</label>
                      {/* <div className={css.clsDropdownCard}>
                        <div className={classNames(css.clsPayCard, css.clsPayCardFull, css.align_items_center, css.justify_content_between, "flex-fill")}>
                          <div className={classNames(css.d_flex, css.align_items_center)}>
                            <img src={visaCard} className="mr-3" alt="" />
                            <div className="clearfix">
                              <h5 className={css.clsMediaTxt1}>Ending in 1509</h5>
                              <p className={css.clsMediaTxt2}>Exp. 05/2026</p>
                            </div>
                          </div>
                        </div>
                        <a className={css.pl_16} href="javascript:;">
                          <img src={downArrow} alt="" />
                        </a>
                      </div> */}
                       <CreditCardDropdown
                    tag={false}
                    options={[{ label: "A", cardEnding: '0059' , expDate: '05/2026' }, { label: "B", cardEnding: '0100' , expDate: '65/2027' }, { label: "C",cardEnding: '0099' , expDate: '05/2028' }]}
                  />
                    </div>
                  </div>
                  <div className="col-lg-5">
                    <h6 className={classNames(css.text_16, css.text_sb, css.text_dark, "text-uppercase mb-2")}>Total</h6>
                    <h4 className={classNames(css.text_30, css.text_sb, css.text_dark, "")}>$30.10</h4>
                  </div>
                </div>
              </div>
            </div>

            <div className={classNames(css.clsModalFooter)}>
              <button className={classNames(css.btn_lg, css.btn_primary, css.text_primary,)} onClick={handleClose}> Cancel</button>
              <button className={classNames(css.btn_lg, css.clsBtnOrng)}>  Purchase Credits </button>
            </div>
          </div>
        </div>
      </div>

    </Modal>
  );
};


const registrationId = getPopupRegistration(PurchaseSavesAndExportsPopup);
PurchaseSavesAndExportsPopup.open = (props = {}) => openPopup(registrationId, { ...props, priority: Priority.MEDIUM });
PurchaseSavesAndExportsPopup.close = () => closePopup({ popup: registrationId });

export default PurchaseSavesAndExportsPopup;







